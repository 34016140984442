<template>
  <div class="row q-mt-md">
    <div
      class=" q-mr-auto q-ml-auto"
      :class="{
        'col-7': $q.screen.width <= 800,
        'col-5': $q.screen.width > 800,
      }"
    >
      <q-input
        filled
        placeholder="X-X-X-X-X-X"
        class="q-pa-sm q-text-center input q-self-center "
        mask="#-#-#-#-#-#"
        v-model="code"
      />
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";

export default {
  name: "GetCodeComponent",

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  emits: ["getCode"],

  setup(props, { emit }) {
    const code = ref("");
    const getCode = () => {
      emit("getCode", code.value.replace(/-/g, ""));
    };

    watch(code, () => getCode());

    return { code, getCode };
  },
};
</script>

<style scoped lang="scss">
.input {
  font-size: 1.5rem;
}
</style>
